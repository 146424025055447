//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import Facebook from '~/assets/icons/ico-facebook.svg'
import Pinterest from '~/assets/icons/ico-pinterest.svg'
import Instagram from '~/assets/icons/ico-instagram.svg'
import { CACHE_SETTINGS } from '~/utils/cache'
import Score from '~/components/score'
import settings from '~/mixins/settings'

export default {
  name: 'FooterContainer',
  components: { Score, Facebook, Pinterest, Instagram },
  mixins: [settings],
  props: {
    showButtons: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      score: null
    }
  },
  mounted () {
    this.getScore()
  },
  methods: {
    async getScore () {
      this.score = await this.$cacheFetch({
        key: 'pIndex-getReviewsScore', expire: CACHE_SETTINGS.baseTime
      }, async () => {
        return await this.$store.dispatch('getRentingScore')
      })
    }
  },
  computed: {
    ...mapState({
      phoneNumbers: state => state.contact.phoneNumbers,
      locale: state => state.lang.locale
    }),
    getContactInfo () {
      return this.$store.getters['contact/getContactInfo'](this.locale)
    }
  }
}
