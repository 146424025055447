//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import settings from '~/mixins/settings'

export default {
  name: 'Score',
  mixins: [settings],
  props: {
    value: {
      type: Number,
      default: 4.9
    }
  }
}
